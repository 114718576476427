<template>
  <div class="team wrap">
    <div class="The_header">
      <ul class="bg-fff">
        <li class="left">
          <router-link to>
            <i class="iconfont icon-fanhui" @click="$router.replace('/grabOrder')"></i>
          </router-link>
        </li>
        <li>{{ t("common.title.Task") }}</li>
      </ul>
    </div>
    <!-- <div class="main main_page main_grab">
      <div class="rob_one">
        <div class="one_box">
          <ol>
            <li>
              <i>{{ t("profile.label.walletBalance") }}</i>
              <span>{{ formatMoney(userDetail.amount) }}</span>
            </li>
            <li>
              <i>{{ t("profile.label.commission") }}</i>
              <span>
               {{ formatMoney(userDetail.commission) }}
              </span>
            </li>
            
            <li>
              <i> {{ t("common.title.orderReturnBenJin") }}</i>
              <span>
                {{ showPaySum }}
              </span>
            </li>
            <li>
              <i> {{ t("common.title.orderReturnYongJin") }}</i>
              <span>
                {{ showYongJinSum }}
              </span>
            </li>
          </ol>
        </div>
      </div>
    </div> -->

    <div class="aui-tab" id="tab">
        <div :class="+itemIndex === 1?'aui-tab-item aui-active':'aui-tab-item'" @click="setActiveItem(1)">{{ t("common.title.trade") }}</div>
        <div :class="+itemIndex === 2?'aui-tab-item aui-active':'aui-tab-item'" @click="setActiveItem(2)">{{ t("common.title.completed") }}</div>
        <div :class="+itemIndex === 3?'aui-tab-item aui-active':'aui-tab-item'" @click="setActiveItem(3)">{{ t("common.title.freeze") }}</div>
    </div>
    <BetterScroll
      ref="scrollRef"
      @pullingUp="fetchMorePage"
      v-if="alreadyReq"
      class="BetterScroll"
    >
      <div class="main main_page main_page2">
        <div class="Order_list">
          <div class="Order_list_box">
            <div class="no_data" v-if="!listItems || listItems.length == 0">
              <p>{{ t("common.message.noRecords") }}</p>
            </div>
            <div
              class="bata"
              :key="index"
              v-for="(item,index) in listItems"
            >
              <ul>
                <li>
                  {{ t("profile.label.time") }}:
                  <b>{{ formatServerDateTime(item.createDate) }}</b>
                </li>
                <li>
                  {{ t("profile.label.serialNumber") }}:
                  <b>{{ item.orderNo }}</b>
                </li>
              </ul>
              
              <ol>
                <h2><img :src="sysDataDictionaryWbObj.TXHQDZ?.dataContent + item.img" alt="" /></h2>
                <h3>{{ item.productName }}</h3>
                <li>
                  <span>{{ formatMoney(item.price) }}</span>
                  <span class="r">x1</span>
                </li>
              </ol>
              
              <ul style="border:0">
                <!-- <li>
                  {{ t("profile.label.orderAmount") }}
                  <span>{{ formatMoney(item.orderSumAmount) }}</span>
                  <span>{{ +item.orderStatus === 4?formatMoney(item.orderSumAmount):returnBenJin(item) }}</span>
                </li>
                <li>
                  {{ t("profile.label.commission") }}
                  <span>{{ formatMoney(item.incomeAmount) }}</span>
                  <span>{{ +item.orderStatus === 4?formatMoney(item.incomeAmount):returnYongJin(item) }}</span>
                </li>
                <li>
                  {{ t("common.title.Paid") }}
                  <span class="c-red">
                    {{ item.paymentAmount?formatMoney(item.paymentAmount):0 }}
                  </span>
                </li> -->
                <li>
                  {{ t("common.title.priceOne") }}
                  <span class="c-red">
                    <span>{{ formatMoney(item.price) }}</span>
                  </span>
                </li> 
                <li>
                  {{ t("profile.label.commission")}}
                  <span class="c-red">
                    <span>{{ formatMoney(item.commission) }}</span>
                  </span>
                </li> 
              </ul>

              <div style="text-align:center;padding: 0 0 8px 0">
                 <router-link to="" @click="goDetail(item)" class="btn">
                    {{ +item.orderStatus === 2?t("common.title.ExcutingEnd"):t("common.title.Excuting") }}
                  </router-link>
              </div>
            </div>
          </div>
        </div>

        
      </div>
    </BetterScroll>
  </div>
</template>
<script>
import { ref,reactive,onMounted,watch } from "vue";
import BetterScroll from "@/components/BetterScroll.vue";
import api from "@/api";
import { sysDataDictionaryWbObj } from '@/hooks/sysDataDictionaryWbObj'
import { taskDetailData } from "@/hooks/taskDetail";
import { useRouter } from "vue-router";
import {
  formatMoney,
  formatMoneySign,
  formatServerDateTime,
  formatPercentage,
  formatShortDateTime,
} from "@/utils/formatter";
import { useI18n } from "@/lang";
import { userDetail } from "@/hooks/userDetail";
export default {
  name: "GrabOrderList2",
  components: { BetterScroll },
  setup() {
    const router = useRouter();
    const { t } = useI18n();
    const scrollRef = ref(null);
    const listItems = reactive([]);

    const currentPage = ref(0);
    const itemIndex = ref(1);
    const alreadyReq = ref(true);
    const isStopReq = ref(false);

    const showPaySum = ref(0);
    const showYongJinSum = ref(0);

    const fetchMorePage = () => {
      if (isStopReq.value) {
        return;
      }
      listItems.length = 0
      api
        .queryOrder({
          current: currentPage.value + 1,
          size: 10,
          state: itemIndex.value
        })
        .then((res) => {
          if (res?.data?.success) {
            alreadyReq.value = true;
            if (+res.data.data?.pages <= +currentPage.value + 1) {
              isStopReq.value = true;
            }
            currentPage.value += 1;
            listItems.push(...res.data.data?.records);
          }
        });
    };

    onMounted(() => {
      fetchMorePage();
    });
    // onActivated(() => {
    //   fetchMorePage();
    // });

    watch(listItems, () => {
      if (scrollRef.value) {
        scrollRef.value.refresh();
      }
    });
    
    function goDetail(item){
      
      taskDetailData.value = item
      // console.log(taskDetailData.value,'1111111111111111111111')
      router.replace("/GrabOrderDetail");
    }



    function setActiveItem(index){
      itemIndex.value = index
      currentPage.value = 0
      alreadyReq.value = false
      isStopReq.value = false
      fetchMorePage()
    }

    return {
      t,
      scrollRef,
      formatMoney,
      formatMoneySign,
      formatPercentage,
      formatServerDateTime,
      formatShortDateTime,
      listItems,
      fetchMorePage,
      alreadyReq,
      sysDataDictionaryWbObj,
      setActiveItem,
      itemIndex,
      goDetail,
      taskDetailData,
      userDetail,
      showPaySum,
      showYongJinSum
    };
  },
};
</script>
<style lang="scss" scoped>
.wrap .main {
  position: relative;
  top: 50;
}
.wrap .main_page2 {
    top: -5px;
}
.Order_list {
  padding: 0;
}
.scroll-wrapper{
  top:42px;
}
.btn{
    display: inline-block;
    width: auto;
    padding: 7px 10px;
    font-size: 14px;
    line-height: normal;
    color: #FFF;
    text-align: center;
    background: #E23D6D;
    border-radius: 28px;
    box-shadow: 3px 3px 4px rgba(0, 0, 0, 0.05);
}

</style>
